import { Typography, View } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type { KeyboardEvent, ReactNode } from 'react';
import styles from './styles.module.css';

interface ChoiceButtonProps {
  illustration: ReactNode;
  title: string;
  subtitle?: string;
  isSelected?: boolean;
  onSelect: () => void;
  datatestid?: string;
}

export function ChoiceButton({
  illustration,
  title,
  subtitle,
  isSelected = false,
  onSelect,
  datatestid,
}: ChoiceButtonProps): ReactNode {
  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.code === 'Enter') {
      onSelect();
    }
  };

  return (
    <div
      className={cx(styles.Wrapper, { [styles.Selected]: isSelected })}
      role="button"
      tabIndex={0}
      data-testid={datatestid}
      onClick={isSelected ? undefined : onSelect}
      onKeyDown={isSelected ? undefined : handleKeyDown}
    >
      <View marginBottom={22}>{illustration}</View>
      <Typography.Paragraph base="body" variant="bold" color={isSelected ? 'white' : 'black'}>
        {title}
      </Typography.Paragraph>
      {Boolean(subtitle) && (
        <Typography.Paragraph base="body" variant="bold" color={isSelected ? 'white-light' : 'black-light'}>
          {subtitle}
        </Typography.Paragraph>
      )}
    </div>
  );
}
