import {
  ArrowLeftRegularIcon,
  CaretDownRegularIcon,
  CaretUpRegularIcon,
  XRegularIcon,
} from '@ornikar/kitt-icons/phosphor';
import { Button, IconButton, NavigationModal, Picker, Typography, VStack } from '@ornikar/kitt-universal';
import type { PickerRenderTriggerProps } from '@ornikar/kitt-universal/dist/definitions/Picker/Picker';
import { useRequiredValidator } from '@ornikar/react-validators';
import * as Sentry from '@sentry/react';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { FieldV2 } from '../../../../../components/FieldV2';
import { InputHighlight } from '../../../../../components/InputHighlight';
import type { VehicleFsmAnswers } from '../../../../../fsm/answers';
import { useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../../../fsm/context';
import { Event } from '../../../../../fsm/types';

interface Props {
  onClose: () => void;
  onChangeStep: (newStep: number) => void;
  currentVehicleData: Partial<VehicleFsmAnswers>;
  onSubmit?: (vehicleImmatriculation: string) => void;
}

export function VehicleStep({ onClose, onChangeStep, onSubmit, currentVehicleData }: Props): ReactNode {
  const validator = useRequiredValidator();
  const {
    context: { answers },
  } = useSubscriptionFsmState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const { values } = useFormState();
  const { change } = useForm();

  const send = useSubscriptionFsmDispatch();

  const choices = useMemo(
    () =>
      currentVehicleData?.vehiculeVersionOptions?.map(({ version, id }: { version: string; id: string }) => ({
        label: version,
        value: id,
      })) || [],
    [currentVehicleData],
  );

  const handleSubmit = (): void => {
    setIsLoading(true);

    try {
      if (answers.vehiculeVersion !== values.newVehiculeVersion) {
        send({
          type: Event.ANSWER,
          answers: {
            ...answers,
            ...currentVehicleData,
            vehiculeVersion: values.newVehiculeVersion,
            hasDoneRevalidation: false,
          },
        });
      } else if (onSubmit) {
        onSubmit(values.vehiculeImmatriculation as string);
      } else {
        send({
          type: Event.CONTINUE,
        });
      }
    } catch (error_) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Sentry.captureException(error_);
      setError(error_);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NavigationModal
      header={
        <NavigationModal.Header
          right={<IconButton icon={<XRegularIcon />} onPress={onClose} />}
          left={<IconButton icon={<ArrowLeftRegularIcon />} onPress={() => onChangeStep(0)} />}
        >
          <Typography.Paragraph textAlign="center" variant="bold">
            <FormattedMessage id="vehicle.vehicle.verification.header.title" defaultMessage="Mon véhicule" />
          </Typography.Paragraph>
        </NavigationModal.Header>
      }
      body={
        <NavigationModal.Body style={{ zIndex: 1000 }}>
          <VStack marginBottom={24}>
            <Typography.Header4 base="header4" variant="bold" textAlign="center">
              <FormattedMessage
                id="vehicle.vehicle.verification.title"
                defaultMessage="Quelle est la version de votre {vehicle}&nbsp;?"
                values={{
                  vehicle: `${currentVehicleData.vehiculeMarque} - ${currentVehicleData.vehiculeModele}`,
                }}
              />
            </Typography.Header4>
          </VStack>
          <VStack>
            <FieldV2
              component={Picker}
              title={<FormattedMessage id="vehicle.vehicle.verification.label" defaultMessage="Version du véhicule" />}
              name="newVehiculeVersion"
              value={values.newVehiculeVersion}
              defaultValue={answers.vehiculeVersion}
              checkSelectedItem={(selectedItem: unknown, item: unknown) => selectedItem === item}
              itemToString={(item: unknown) => choices.find((v) => v.value === item)?.label ?? ''}
              validate={validator}
              renderTrigger={({ isOpen, selectedItem, onPress, ...rest }: PickerRenderTriggerProps<unknown>) => (
                <Button
                  {...rest}
                  iconPosition="right"
                  icon={isOpen ? <CaretUpRegularIcon /> : <CaretDownRegularIcon />}
                  onPress={onPress}
                >
                  {choices.find((v) => v.value === selectedItem)?.label ?? (
                    <FormattedMessage id="vehicle.vehicle.verification.input" defaultMessage="Sélectionner" />
                  )}
                </Button>
              )}
              onChange={(value: unknown) => {
                change('newVehiculeVersion', value);
              }}
            >
              {choices.map(({ value, label }) => (
                <Picker.Option key={value} value={value}>
                  {label}
                </Picker.Option>
              ))}
            </FieldV2>
          </VStack>
          {error ? (
            <Typography.Paragraph color="danger" base="body-small">
              {error.message}
            </Typography.Paragraph>
          ) : null}
          <InputHighlight
            title={
              <FormattedMessage id="vehicle.vehicle.highlight.title" defaultMessage="Où trouver la version&nbsp;?" />
            }
          >
            <Typography.Text>
              <FormattedMessage
                id="vehicle.vehicle.highlight.content"
                defaultMessage="Rendez-vous au <bold>repère D2</bold> de votre <bold>carte grise</bold> (certificat d'immatriculation)"
                values={{
                  bold: (chunks: ReactNode[]) => <Typography.Text variant="bold">{chunks}</Typography.Text>,
                }}
              />
            </Typography.Text>
          </InputHighlight>
        </NavigationModal.Body>
      }
      footer={
        <NavigationModal.Footer>
          <VStack justifyContent="center" position="relative">
            <Button
              stretch
              type="primary"
              disabled={(!values.vehiculeVersion && !values.newVehiculeVersion) ?? isLoading}
              onPress={handleSubmit}
            >
              {values.vehiculeVersion !== values.newVehiculeVersion ? (
                <FormattedMessage id="vehicle.vehicle.footer.calculate" defaultMessage="Recalculer mon tarif" />
              ) : (
                <FormattedMessage id="vehicle.vehicle.footer.continue" defaultMessage="Continuer" />
              )}
            </Button>
          </VStack>
        </NavigationModal.Footer>
      }
    />
  );
}
