import { HStack, Typography, VStack, View } from '@ornikar/kitt-universal';
import type { ComponentProps, ReactNode } from 'react';
import type { Except } from 'type-fest';

type PartialBy<T, K extends keyof T> = Except<T, K> & Partial<Pick<T, K>>;
type TypographyProps = ComponentProps<typeof Typography>;

const getLabelProps = (
  checked: boolean,
  withSubText: boolean,
): PartialBy<{ color: TypographyProps['color']; variant: TypographyProps['variant'] }, 'color'> =>
  checked
    ? {
        color: 'white',
        variant: 'bold',
      }
    : { variant: withSubText ? 'bold' : 'regular' };

const getSubTextProps = (checked: boolean): PartialBy<{ color: TypographyProps['color'] }, 'color'> =>
  checked
    ? {
        color: 'white-light',
      }
    : {};

interface RadioBlockProps {
  children: ReactNode;
  checked: boolean;
  subText?: string;
}

export function RadioBlock({ children, checked, subText }: RadioBlockProps): ReactNode {
  return (
    <HStack alignItems="center" space="kitt.4">
      <View
        width="kitt.6"
        height="kitt.6"
        borderRadius="kitt.3"
        backgroundColor="#fff"
        borderWidth={checked ? 2 : 0}
        borderColor="kitt.forms.radio.default.borderColor"
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignSelf="flex-start"
      >
        <View
          width="kitt.2"
          height="kitt.2"
          borderRadius="kitt.1"
          backgroundColor="kitt.forms.radio.checked.backgroundColor"
          opacity={checked ? 1 : 0}
        />
      </View>
      <VStack space="kitt.2" flex={1}>
        <Typography.Text {...getLabelProps(checked, Boolean(subText))}>{children}</Typography.Text>
        {subText ? <Typography.Text {...getSubTextProps(checked)}>{subText}</Typography.Text> : null}
      </VStack>
    </HStack>
  );
}
