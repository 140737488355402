import type { ReactNode } from 'react';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { DateField } from '../../../components/DateField';
import { FieldV2 } from '../../../components/FieldV2';
import { ScreenTemplateWithValidation } from '../../../components/ScreenTemplates/ScreenTemplateWithValidation';
import { useVehiculeCoverageDateValidator } from '../../../forms/validation/sections/vehicle/vehiculeCoverageDateValidator';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { useVehicleCoverageMaxDate } from './useVehicleCoverageMaxDate';
import { useVehicleCoverageMinDate } from './useVehicleCoverageMinDate';

const fieldName = 'dateEffetSouhaite';

export function VehicleCoverageDateScreen(): ReactNode {
  const { context } = useSubscriptionFsmState();
  const isDesktop = useInsuranceDesktopMediaQuery();

  const vehiculeCoverageDateValidator = useVehiculeCoverageDateValidator(context.answers.vehiculeAcquisition!);

  const minDate = useVehicleCoverageMinDate(context.answers.vehiculeAcquisition!);
  const maxDate = useVehicleCoverageMaxDate();

  return (
    <ScreenTemplateWithValidation
      fieldNames={[fieldName]}
      title="Quand souhaitez-vous assurer votre véhicule&nbsp;?"
      highlightTitle="Précision importante"
      highlightContent={
        <div>
          Assurer votre véhicule est obligatoire, y compris lorsque vous ne l&apos;utilisez pas.
          <br />
          <br />
          Dans certaines situations, votre responsabilité peut-être engagée que vous soyez ou non au volant !<br />
          <br />
          De plus, une interruption d&apos;assurance, même d&apos;un jour, peut s&apos;avérer bloquante pour vous
          fournir un tarif.
        </div>
      }
    >
      {isDesktop ? (
        <FieldV2
          name={fieldName}
          component={CustomDatePicker}
          minDate={minDate}
          maxDate={maxDate}
          validate={vehiculeCoverageDateValidator}
        />
      ) : (
        <DateField name={fieldName} validate={vehiculeCoverageDateValidator} minDate={minDate} maxDate={maxDate} />
      )}
    </ScreenTemplateWithValidation>
  );
}
