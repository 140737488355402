import { Autocomplete, Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSubmitWithDelay } from '../../hooks/useSubmitWithDelay';

export interface Choice<T> {
  value: T;
  label: string;
}

interface LocalInputAutoCompleteProps<Value> {
  choices: Choice<Value>[];
  value: Value;
  name: string;
  onChange: (value: Value) => void;
  onCustomInputValueChange?: (value: string) => void;
  onBlur: () => void;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  emptyComponent?: ReactNode;
  maxItemContainerHeight?: number;
  testID?: string;
}

export function LocalInputAutoComplete<Value>({
  choices,
  value,
  name,
  onChange,
  onCustomInputValueChange,
  maxItemContainerHeight,
  onBlur,
  placeholder,
  disabled,
  isLoading,
  emptyComponent,
  testID,
}: LocalInputAutoCompleteProps<Value>): ReactNode {
  const [isOpen, setOpen] = useState<boolean>(false);
  const selectedItemNameRef = useRef(choices.find((choice) => choice.value === value)?.label);

  const defaultValue = value ? choices.find((c) => c.value === value) : undefined;
  const [search, setSearch] = useState(defaultValue?.label);

  useEffect(() => {
    setSearch(defaultValue?.label);
  }, [defaultValue]);

  return (
    <View maxHeight={160} width="100%" zIndex={4}>
      <Autocomplete<Choice<Value>>
        name={name}
        inputTestID={testID}
        initialValue={defaultValue}
        value={search!}
        disabled={disabled}
        placeholder={placeholder}
        emptyResultsElement={
          <View paddingX="kitt.4">
            <Typography.Text>{isLoading ? 'Chargement...' : emptyComponent || 'Pas de résultat'}</Typography.Text>
          </View>
        }
        itemToString={(item) => item?.label ?? ''}
        isInitialOpen={isOpen}
        checkSelectedItem={(selectedItem, item) => (selectedItem && item ? selectedItem.value === item.value : false)}
        filterItemBasedOnCurrentInputValue={(item, inputValue) =>
          item.label?.toLowerCase().includes(inputValue.toLowerCase())
        }
        maxItemContainerHeight={maxItemContainerHeight ?? 500}
        onInputChange={(searchValue: string) => {
          if (typeof searchValue === 'string') {
            let formattedSearchValue = searchValue;
            if (typeof value === 'number') {
              formattedSearchValue = formattedSearchValue.replace(/,/g, '.');
            }

            setSearch(formattedSearchValue);
          }
          if (onCustomInputValueChange) {
            onCustomInputValueChange(searchValue);
          }
        }}
        onSelectItem={(selectedItem: Choice<Value> | null) => {
          if (selectedItem) {
            setOpen(false);
            setSearch(selectedItem.label);
            onChange(selectedItem.value);
            onBlur();

            selectedItemNameRef.current = selectedItem.label;
          }
        }}
      >
        {choices.map((item) => (
          <Autocomplete.Option key={`${item.value as string}`} item={item}>
            <Typography.Text variant="bold">{item.label}</Typography.Text>
          </Autocomplete.Option>
        ))}
      </Autocomplete>
    </View>
  );
}

export interface LocalAutoCompleteInputProps<Value> extends LocalInputAutoCompleteProps<Value> {
  submitOnChange?: boolean;
  autoComplete?: string;
}

export function LocalAutoCompleteInput<Value>({
  onChange,
  submitOnChange,
  ...rest
}: LocalAutoCompleteInputProps<Value>): ReactNode {
  const submitWithDelay = useSubmitWithDelay();

  return (
    <LocalInputAutoComplete
      {...rest}
      onChange={(value: Value) => {
        if (onChange) {
          onChange(value);
        }

        if (submitOnChange) {
          submitWithDelay();
        }
      }}
    />
  );
}
