import { Typography, View, useTheme } from '@ornikar/kitt-universal';
import cx from 'classnames';
import type React from 'react';
import type { ComponentProps, DetailedHTMLProps, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import Slider from 'react-slider';
import styles from './styles.module.css';

type InputProps = ComponentProps<'input'>;

export interface SliderInputProps {
  name: string;
  min?: number;
  minLabel?: string;
  max?: number;
  maxLabel?: string;
  step?: number;
  value?: string;
  defaultSliderValue?: number;
  label?: string;
  unit?: string;
  formatValue?: (value: number) => string;
  onChange: InputProps['onChange'];
  onBlur?: InputProps['onBlur'];
  onFocus?: InputProps['onFocus'];
}

export function SliderInput({
  min,
  minLabel,
  max,
  maxLabel,
  value,
  label = '',
  unit,
  defaultSliderValue,
  onChange,
  formatValue = (v) => v.toString(),
  ...props
}: SliderInputProps): ReactNode {
  const onValueChange = useCallback(
    (v: number) => {
      // @ts-expect-error (we create fake event object)
      onChange({ target: { value: v.toString() } });
    },
    [onChange],
  );
  const theme = useTheme();

  const renderThumb = useCallback(
    (
      properties: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
      { value: val }: { value: number },
    ) => (
      <div {...properties}>
        <View
          position="absolute"
          bottom={40}
          left={-40}
          borderRadius="kitt.30"
          justifyContent="center"
          display="flex"
          alignItems="center"
          padding={10}
          backgroundColor={theme.kitt.palettes.lateOcean.black50}
          minWidth={{
            base: 100,
            medium: 120,
          }}
        >
          <Typography.Text variant="bold" base="body-xs" medium="body-m">
            {formatValue(val)} {unit}
          </Typography.Text>
        </View>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatValue, unit],
  );

  const renderTrack = useCallback(
    ({ className, ...rest }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
      const isLeftPart = className?.indexOf('-1') !== -1;
      const finalClassName = isLeftPart ? cx(className, styles.TrackBackground) : className;
      return <div {...rest} className={finalClassName} />;
    },
    [],
  );

  const minimum = useMemo(() => {
    if (minLabel) {
      return minLabel;
    }

    if (min) {
      if (unit) {
        return `${formatValue(min)} ${unit}`;
      }

      return formatValue(min);
    }

    return null;
  }, [minLabel, min, unit, formatValue]);

  const maximum = useMemo(() => {
    if (maxLabel) {
      return maxLabel;
    }

    if (max) {
      if (unit) {
        return `${formatValue(max)} ${unit}`;
      }

      return formatValue(max);
    }

    return null;
  }, [maxLabel, max, unit, formatValue]);

  return (
    <div className={styles.Slider}>
      {minimum && (
        <View color="kitt.blackLight" position="absolute" bottom={24}>
          {minimum}
        </View>
      )}
      {maximum && (
        <View color="kitt.blackLight" position="absolute" bottom={24} right={24}>
          {maximum}
        </View>
      )}
      <Slider
        className={styles.SliderComponent}
        value={value ? parseInt(value, 10) : undefined}
        min={min}
        max={max}
        ariaLabel={label}
        thumbClassName={styles.Thumb}
        trackClassName={styles.Track}
        renderThumb={renderThumb}
        renderTrack={renderTrack}
        defaultValue={defaultSliderValue}
        onChange={onValueChange}
        {...props}
      />
    </div>
  );
}
