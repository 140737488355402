import { InputText, Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { filterValidEnergies } from '../../../apis/vehicules';
import { Dropdown } from '../../../components/Dropdown';
import type { FieldProps } from '../../../components/Field';
import { Field } from '../../../components/Field';
import { useSubscriptionFsmState } from '../../../fsm/context';
import { useAsync } from '../../../hooks/useAsync';
import { useSubmitWithDelay } from '../../../hooks/useSubmitWithDelay';
import { serviceUnavailable } from '../../../utils/errorMessages';

export function CarburantField(props: Pick<FieldProps<string>, 'name' | 'label' | 'validate'>): ReactNode {
  const { context } = useSubscriptionFsmState();
  const submitWithDelay = useSubmitWithDelay();

  const [validEnergies, error, loading] = useAsync(() => {
    return filterValidEnergies(
      context.answers.vehiculeMarque,
      context.answers.vehiculeModele,
      context.answers.vehiculePuissanceFiscale,
      context.availableCarburants,
    );
  }, []);

  const choices = useMemo(() => {
    return (validEnergies || []).map((v) => ({ label: v, value: v }));
  }, [validEnergies]);

  const placeholderText = loading ? 'Chargement...' : 'Sélectionner';

  const onChange = (): void => {
    submitWithDelay();
  };

  if (error) {
    return (
      <>
        <Field {...props} disabled component={InputText} />
        <Typography.Text color="kitt.danger">{serviceUnavailable}</Typography.Text>
      </>
    );
  }

  return (
    <div data-testid="div.vehicleCarburantScreen.CarburantField.fuelType">
      <Field {...props} component={Dropdown} placeholder={placeholderText} values={choices} onChange={onChange} />
    </div>
  );
}
