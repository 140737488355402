import { useRequiredValidator } from '@ornikar/react-validators';
import type { ReactNode } from 'react';
import { useForm } from 'react-final-form';
import { Civility } from '../../../../apis/types/LongQuoteAnswers';
import { yearsSinceHighestBonusOptionsList } from '../../../../constants/mappers/information';
import { useSubscriptionFsmState } from '../../../../fsm/context';
import { Driver } from '../../../../fsm/types';
import { Dropdown } from '../../../Dropdown';
import { Field } from '../../../Field';
import type { PrimaryOrSecondaryScreenTemplateType } from '../../../drivingSection/types';
import { ScreenTemplate } from '../../ScreenTemplate';

export function InformationBonus50Template({ driverType }: PrimaryOrSecondaryScreenTemplateType): ReactNode {
  const isPrimaryDriver = driverType === Driver.Primary;
  const { context } = useSubscriptionFsmState();
  const attribut = context.answers[`${driverType}Driver`]?.civility === Civility.Homme ? 'il' : 'elle';
  const requiredValidator = useRequiredValidator();
  const { submit } = useForm();

  const onChange = (): void => {
    submit();
  };

  return (
    <ScreenTemplate
      title={
        isPrimaryDriver ? (
          <>Depuis combien d&apos;années bénéficiez-vous de ce bonus&nbsp;?</>
        ) : (
          <>Depuis combien d&apos;années bénéficie-t-{attribut} de ce bonus&nbsp;?</>
        )
      }
    >
      <Field
        component={Dropdown}
        name={`${driverType}Driver.yearsSinceHighestBonus`}
        values={yearsSinceHighestBonusOptionsList}
        placeholder="Sélectionnez le nombre d'années"
        validate={requiredValidator}
        onChange={onChange}
      />
    </ScreenTemplate>
  );
}
