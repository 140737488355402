import type { IllustratedIconSize } from '@ornikar/illustrated-icons';
import { IllustratedIcon } from '@ornikar/illustrated-icons';
import { MapPinRegularIcon } from '@ornikar/kitt-icons/phosphor';
import { Autocomplete, HStack, Typography, View } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useField } from 'react-final-form';
import type {
  GeocodingDto,
  GeocodingListDto,
  PartialGeocodingDto,
  PartialGeocodingListDto,
} from '../../apis/getAddressSuggestions';
import { GeocodingProvider, getAddressDetails, getAddressSuggestions } from '../../apis/getAddressSuggestions';
import type { InformationFormValues } from '../../forms/answers';
import { useAsyncDebouncedMemo } from '../../hooks/useAsyncDebouncedMemo';
import { sendNoOptionsFoundEvent } from '../../utils/mixpanel';

const MIN_USER_INPUT_LENGTH_FOR_API_CALL = 3;
const DEBOUNCE_DELAY_FOR_API_CALL = 950;

export interface CustomInputAddressProps {
  name: string;
  placeholder?: string;
  initialFormattedAddress?: GeocodingDto;
  emptyMessage?: string | ReactNode;
  onChange: (value?: GeocodingDto) => void;
  onBlur: () => void;
}

export function CustomInputAddress({
  name,
  initialFormattedAddress,
  placeholder,
  emptyMessage = 'Pas de résultat',
  onBlur,
  onChange,
}: CustomInputAddressProps): ReactNode {
  const inputRef = useRef<HTMLInputElement>(null);
  const [userInput, setUserInput] = useState(initialFormattedAddress?.formattedAddress || '');

  const [lastAddress, setLastAddress] = useState(
    initialFormattedAddress
      ? {
          ...initialFormattedAddress,
          suggestedAddress: initialFormattedAddress?.formattedAddress,
        }
      : undefined,
  );
  const [loading, setLoading] = useState(false);
  const {
    input: { onChange: updateAddress },
  } = useField(name, {
    initialValue: initialFormattedAddress,
  });

  const suggestionsResponse: GeocodingListDto | PartialGeocodingListDto | undefined = useAsyncDebouncedMemo(
    async (signal) => {
      if (!userInput || userInput.length < MIN_USER_INPUT_LENGTH_FOR_API_CALL) {
        return undefined;
      }

      // to avoid unnecessary second call to getAddressSuggestions on item selected
      const matchingSuggestion: PartialGeocodingDto | undefined =
        suggestionsResponse &&
        (suggestionsResponse.suggestions as PartialGeocodingDto[])?.find(
          (suggestion) => suggestion.formattedAddress === userInput,
        );

      if (matchingSuggestion) {
        setLoading(false);
        return {
          suggestions: [matchingSuggestion],
          source: suggestionsResponse ? suggestionsResponse.source : GeocodingProvider.GOOGLE_MAPS,
        };
      }

      const newSuggestionsResponse = await getAddressSuggestions(userInput, signal);
      setLoading(false);
      return newSuggestionsResponse;
    },
    DEBOUNCE_DELAY_FOR_API_CALL,
    [userInput],
    { cancelable: true },
  );

  useEffect(() => {
    if (!loading && (!suggestionsResponse?.suggestions || suggestionsResponse.suggestions.length === 0)) {
      sendNoOptionsFoundEvent(name as keyof InformationFormValues);
    }
  }, [suggestionsResponse?.suggestions, loading, name]);

  return (
    <View width="100%" zIndex={3}>
      <Autocomplete
        initialValue={initialFormattedAddress}
        placeholder={placeholder}
        inputTestID="field.CustomInputAddress.autocomplete"
        emptyResultsElement={
          userInput && userInput.length >= MIN_USER_INPUT_LENGTH_FOR_API_CALL && !loading ? (
            <Autocomplete.Option item={{ value: undefined }}>
              <Typography.Text>{emptyMessage}</Typography.Text>
            </Autocomplete.Option>
          ) : loading ? (
            <Autocomplete.Option item={{ value: undefined }}>
              <Typography.Text>Chargement...</Typography.Text>
            </Autocomplete.Option>
          ) : null
        }
        checkSelectedItem={(selectedItem, item) =>
          selectedItem && item ? selectedItem.formattedAddress === item.formattedAddress : false
        }
        itemToString={(item?: GeocodingDto | PartialGeocodingDto | undefined): string => item?.formattedAddress ?? ''}
        onFocus={() => {
          if (inputRef?.current) {
            inputRef.current.select();
          }
        }}
        onInputChange={(value: string) => {
          if (!value) {
            setLastAddress({
              city: '',
              zipCode: '',
              formattedAddress: '',
              street: '',
              suggestedAddress: '',
            });
          }

          const isInputLengthEnough = value.length >= MIN_USER_INPUT_LENGTH_FOR_API_CALL;

          setUserInput(value);

          if (isInputLengthEnough) {
            setLoading(true);
          } else {
            setLoading(false);
          }
        }}
        onBlur={() => {
          if (!userInput) {
            updateAddress(undefined);
            onChange(undefined);
          }

          if (userInput && lastAddress && userInput !== lastAddress.suggestedAddress) {
            setUserInput(lastAddress.suggestedAddress);
          }

          if (onBlur) {
            onBlur();
          }
        }}
        onSelectItem={async (item: GeocodingDto | PartialGeocodingDto | null) => {
          if (item) {
            const addressDetails = await getAddressDetails((item as PartialGeocodingDto).placeId);

            setUserInput(addressDetails.formattedAddress);
            updateAddress(addressDetails);
            onChange(addressDetails);
            setLastAddress({
              ...addressDetails,
              suggestedAddress: item.formattedAddress,
            });
            inputRef?.current?.blur();
          }
        }}
      >
        {(userInput && userInput.length >= MIN_USER_INPUT_LENGTH_FOR_API_CALL && suggestionsResponse?.suggestions
          ? suggestionsResponse.suggestions
          : []
        ).map((suggestion) => (
          <Autocomplete.Option key={suggestion.formattedAddress} item={suggestion} flexDirection="row">
            <HStack space="kitt.1" alignItems="center">
              <IllustratedIcon
                align="baseline"
                icon={<MapPinRegularIcon fill="primary" width={20} />}
                size={24 as IllustratedIconSize}
              />
              <Typography.Text variant="bold">{suggestion.formattedAddress}</Typography.Text>
            </HStack>
          </Autocomplete.Option>
        ))}
      </Autocomplete>
    </View>
  );
}
