import type { ReactNode } from 'react';
import type { GeocodingDto } from '../../apis/getAddressSuggestions';
import { useAutocompleteAddressValidator } from '../../forms/validation/sections/informations/address';
import { FieldV2 } from '../FieldV2';
import { CustomInputAddress } from './CustomInputAddress';

export interface AddressAutocompleteFormFieldProps {
  name: string;
  initialFormattedAddress?: GeocodingDto;
  label: string;
  placeholder?: string;
  emptyMessage?: string | ReactNode;
}

export function AddressAutocompleteFormField({
  name,
  initialFormattedAddress,
  label,
  placeholder,
  emptyMessage,
}: AddressAutocompleteFormFieldProps): ReactNode {
  const autocompleteAddressValidator = useAutocompleteAddressValidator();

  return (
    <FieldV2
      formatOnBlur
      testID="field.AddressAutocompleteFormField.autocomplete"
      label={label}
      name={name}
      emptyMessage={emptyMessage}
      initialFormattedAddress={initialFormattedAddress}
      validate={autocompleteAddressValidator}
      component={CustomInputAddress}
      placeholder={placeholder}
    />
  );
}
